<template lang="pug">
    div
        v-snackbar(
        :timeout="3000"
        :top="true"
        :right="true"
        :color="alertColor"
        v-model="alertMsj")
            | {{ alertText }}
            v-btn(flat dark @click.native="alertMsj = false") {{ $t('cerrar') }}
        v-dialog(lazy max-width="900px" v-model="wpgroup" persistent)
            v-card
                v-card-title 
                    .btns.btns-right
                        v-btn(icon color="secondary" small @click.native="wpgroup=false")
                            v-icon close
                    h3.full-width {{ $t('Grupos de WhatsApp') }} - {{ current.name }}
                v-card-text
                    template(v-if="preload")
                        h3 {{$t('Cargando')}}...
                    template(v-else)
                        v-layout.mt-4(row wrap)
                            h4.full-width {{ $t('Grupos de WhatsApp por condiciones') }}
                            template(v-if="loadEdit")
                                h3 {{$t('Cargando')}}...
                            template(v-else)
                                v-flex(xs12 sm6)
                                    v-text-field(:label='$t("Nombre del grupo")' v-model="modelRule.group" required type="text")
                                v-flex(xs12 sm6)
                                    v-text-field(:label='$t("URL del grupo")' v-model="modelRule.wpgroup" required type="text")
                                v-flex(xs12 sm6)
                                    v-select.mb-3(
                                        v-model="modelRule.country"
                                        :items='countries'
                                        item-text="name"
                                        item-value="uuid"
                                        :label='$t("País")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                        :return-object="true"
                                        @input="modelRule.state={},modelRule.city={},states=[],cities=[],fetchState()"
                                        clearable
                                    )
                                v-flex(xs12 sm6)
                                    v-select.mb-3(
                                        v-model="modelRule.state"
                                        :items='states'
                                        item-text="name"
                                        item-value="uuid"
                                        :label='$t("Estado/Departamento")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                        :return-object="true"
                                        @input="modelRule.city={},cities=[],fetchCity()"
                                        clearable
                                    )
                                v-flex(xs12 sm6)
                                    v-select.mb-3(
                                        v-model="modelRule.city"
                                        :items='cities'
                                        item-text="name"
                                        item-value="uuid"
                                        :label='$t("Ciudad")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                        :return-object="true"
                                        clearable
                                    )
                                v-flex(xs12 sm6)
                                    v-select(
                                        v-model="modelRule.gender"
                                        :items='gender'
                                        item-text="text"
                                        item-value="id"
                                        :label='$t("Género")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :return-object="true"
                                        :filter="vFilter"
                                        clearable
                                    )
                                v-flex(xs12 sm6)
                                    v-select(
                                        v-model="modelRule.age"
                                        :items='ages'
                                        item-text="text"
                                        item-value="id"
                                        :label='$t("Rango de edad")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :return-object="true"
                                        :filter="vFilter"
                                        clearable
                                    )
                                v-flex(xs12)
                                    .btns.btns-right
                                        v-btn(v-if="modelRule.hasOwnProperty('uuid') && modelRule.uuid ? false : true" color="secondary" small @click="addGroupRule(false)") {{ $t('Agregar grupo') }}
                                        v-btn(v-else color="secondary" small @click="addGroupRule(true)") {{ $t('Editar grupo') }}
                            v-flex.network_rule(xs12)
                                draggable(tag="v-list" :list.sync="modelRule.groups" :options="optsRulesDrag")
                                    v-list-tile.optsr--drag(v-for="(group, i) in modelRule.groups" :key="'networkr'+i")
                                        v-list-tile-action
                                            v-icon.optsr--handle.cur-p.primary--text drag_indicator
                                        v-list-tile-content 
                                            b {{ group.group }}
                                            small
                                                template(v-if="group.country && Object.keys(group.country).length") {{ $t('País') }}: {{ group.country.name }}&nbsp;
                                                template(v-if="group.state && Object.keys(group.state).length") {{ $t('Estado/departamento') }}: {{ group.state.name }}&nbsp;
                                                template(v-if="group.city && Object.keys(group.city).length") {{ $t('Ciudad') }}: {{ group.city.name }}&nbsp;
                                                template(v-if="group.gender && Object.keys(group.gender).length") {{ $t('Género') }}: {{ group.gender.text }}&nbsp;
                                                template(v-if="group.age && Object.keys(group.age).length") {{ $t('Rango de edad') }}: {{ group.age.text }}&nbsp;

                                        v-list-tile-action(style="display:inline-block;")
                                            v-icon(@click="toEdit(group)" v-if="group.hasOwnProperty('uuid') && group.uuid") edit
                                            v-icon(@click="toDelete(group, i)") delete
                v-card-actions
                    .btns.btns-right
                        v-btn(color="secondary" small @click.native="wpgroup = false") {{ $t('Cancelar') }}
                        v-btn(color="primary" large @click.native="save" :loading="loadingBtn") {{ $t('Guardar') }}
        confirm(ref="delete" @action="deleteElement" :title="$t('Eliminar grupo de WhatsApp')" :message="$t('¿Estás seguro de eliminar este grupo de WhatsApp, los usuarios asociados ya no podrán interactuar con este grupo?')")
</template>

<script>

import config from 'src/config'
import fn from 'mixins/fn'
import draggable from 'vuedraggable'

export default {
    components: {
        draggable
    },
    mixins: [fn],
    data(){
        return {
            wpgroup : false,
            alertMsj   : false,
            alertColor : '',
            alertText  : '',
            preload    : true,
            current    : {},
            loadingBtn : false,
            networks   : [],
            countries  : [],
            states     : [],
            cities     : [],
            modelRuleSchema: {
               group    : '',
               wpgroup  : '',
               country  : {},
               state    : {},
               city     : {},
               gender   : {},
               age      : {},
               groups   : [] 
            },
            modelRule: {},
            optsDrag: {
                group    : 'opts',
                draggable: '.opts--drag',
                handle   : '.opts--handle'
            },
            optsRulesDrag: {
                group    : 'optsr',
                draggable: '.optsr--drag',
                handle   : '.optsr--handle'
            },
            loadEdit: false
        }
    },
    computed: {
        imgurl(){
            return config.imgurl
        },
        uploadurl(){
            return config.uploadurl
        }
    },
    methods: {

        fetchNetwork(){

            this.groups = []
            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/network', {
                        params: {
                            list    : 1,
                            exclude : JSON.stringify([this.current.uuid])
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.groups = data.data
                        }

                        resolve()

                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },
        fetchCountries(){

            this.countries = []
            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/country', {
                        params: {
                            filter: JSON.stringify({
                                status: 1
                            })
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.countries = data.data
                        }

                        resolve()

                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },
        fetchState(){

            this.states = []
            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/state', {
                        params: {
                            countryid: this.modelRule.country.uuid,
                            filter: JSON.stringify({
                                status: 1
                            })
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.states = data.data
                        }
                        resolve()
                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },
        fetchCity(){

            this.cities = []
            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/city', {
                        params: {
                            stateid: this.modelRule.state.uuid,
                            filter: JSON.stringify({
                                status: 1
                            })
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.cities = data.data
                        }
                        resolve()
                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },

        addGroupRule(edit){

            try{

                if(!this.modelRule.group.toString().trim().length){
                    throw "Inserte un nombre para el grupo"
                }

                if(!this.modelRule.wpgroup.toString().trim().length){
                    throw "Inserte un enlace de WhatsApp para el grupo"
                }

            }catch(e){

                this.alertMsj   = true
                this.alertText  = e
                this.alertColor = 'error'

                return
            }

            if(edit){

                let idx = this.modelRule.groups.findIndex(g => g.uuid == this.modelRule.uuid)
                if(idx != -1){

                    this.modelRule.groups.splice(idx, 1, {
                        uuid    : this.modelRule.uuid,
                        group   : this.modelRule.group,
                        wpgroup : this.modelRule.wpgroup,
                        country : this.modelRule.country,
                        state   : this.modelRule.state,
                        city    : this.modelRule.city,
                        gender  : this.modelRule.gender,
                        age     : this.modelRule.age
                    })
                }

            }else{

                this.modelRule.groups.push({
                    group   : this.modelRule.group,
                    wpgroup : this.modelRule.wpgroup,
                    country : this.modelRule.country,
                    state   : this.modelRule.state,
                    city    : this.modelRule.city,
                    gender  : this.modelRule.gender,
                    age     : this.modelRule.age
                })
            }

            let groups   = this.modelRule.groups.slice(0)
            this.modelRule = this._.cloneDeep(this.modelRuleSchema)
            this.modelRule.groups = groups
        },

        async open(item){

            this.preload = true

            this.current   = this._.cloneDeep(item)
            this.modelRule = this._.cloneDeep(this.modelRuleSchema)

            await this.$nextTick()

            this.wpgroup = true
            await this.fetchNetwork()
            await this.fetchCountries()
            await this.fetch()

            this.preload = false
        },

        fetch(){

            return new Promise((resolve) => {

                this.$api(this, (xhr) => {
                    xhr.get('/wpgroup', {
                        params: {
                            network: this.current.uuid
                        }
                    }).then((r) => {
                        
                        let data = r.data
                        if(data.response){
                            let rules = data.data.map((r => {

                                if('gender' in r){
                                    r.gender = this.gender.find(g => g.id == r.gender)
                                }

                                if('age' in r){
                                    r.age = this.ages.find(a => a.id == r.age)
                                }

                                return r
                            }))

                            if(rules.length){
                                this.modelRule.groups = rules
                            }
                        }
                        resolve()

                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },

        save(){

            this.loadingBtn = true

            let groupRules = this.modelRule.groups.map((n) => {
                return {
                    uuid    : n.hasOwnProperty('uuid') ? '' : n.uuid,
                    group   : n.group,
                    wpgroup : n.wpgroup,
                    country : !n.hasOwnProperty('country') ? '' : (typeof n.country == 'object' ? (Object.keys(n.country).length ? n.country.uuid : '') : ''),
                    state   : !n.hasOwnProperty('state') ? '' : (typeof n.state == 'object' ? (Object.keys(n.state).length ? n.state.uuid : '') : ''),
                    city    : !n.hasOwnProperty('city') ? '' : (typeof n.city == 'object' ? (Object.keys(n.city).length ? n.city.uuid : '') : ''),
                    gender  : !n.hasOwnProperty('gender') ? '' : (typeof n.gender == 'object' ? (Object.keys(n.gender).length ? n.gender.id : '') : ''),
                    age     : !n.hasOwnProperty('age') ? '' : (typeof n.age == 'object' ? (Object.keys(n.age).length ? n.age.id : '') : ''),
                }
            })

            this.$api(this, (xhr) => {
                xhr.post('/wpgroup', this.$qs.stringify({
                    group_rules : JSON.stringify(groupRules),
                    network     : this.current.uuid 
                })).then((r) => {

                    let data = r.data
                    this.loadingBtn = false
                    this.alertMsj   = true
                        
                    if(data.response){

                        this.alertColor = 'success'
                        this.alertText  = this.$t(data.message)
                        
                        this.wpgroup = false
                        this.$emit('success')
                        
                    }else{
                        this.alertColor = 'error'
                        this.alertText  = this.$t(data.message)
                    }
                }).catch(() => {
                    this.loadingBtn = false
                })
            })
        },

        async toEdit(group, i){

            this.loadEdit  = true
            this.modelRule = this._.merge(this.modelRule, this._.cloneDeep(group))

            await this.fetchCountries()
            await this.fetchState()
            await this.fetchCity()

            this.loadEdit = false
        },

        toDelete(group, i){
            if(group.hasOwnProperty('uuid') && group.uuid){
                this.$refs.delete.open(i)
            }else{
                this.modelRule.groups.splice(i, 1)
            }
        },

        deleteElement(i){
            
            this.modelRule.groups.splice(i, 1)
            this.$refs.delete.close()
        }
    }
}

</script>