<template lang="pug">
    div
        v-snackbar(
        :timeout="3000"
        :top="true"
        :right="true"
        :color="saveColor"
        v-model="saveMsj")
            | {{ saveText }}
            v-btn(flat dark @click.native="saveMsj = false") {{ $t('cerrar') }}
        v-dialog(lazy max-width="900px" v-model="color")
            v-card
                v-card-title 
                    .btns.btns-right
                        v-btn(icon color="secondary" small @click.native="color=false")
                            v-icon close
                    h3.full-width {{ $t('Red') }} {{ current.name }}
                v-card-text
                    template(v-if="preload")
                        h3 {{$t('Cargando')}}...
                    template(v-else)
                        color-setup(v-model="model.colors")
                        color-filter-all(v-model="model" :network="true" :networkid="current.uuid")
                v-card-actions
                    .btns.btns-right
                        v-btn(color="secondary" small @click="color=false") {{$t('Aceptar')}}
                        v-btn(v-if="!preload" color="secondary" :loading="loadingBtn" :disabled="loadingBtn" small @click="save") {{$t('Guardar')}}
</template>

<script>

import Vue from 'vue'
import ColorSetup from 'components/colors/Setup'
import ColorFilterAll from 'components/colors/SetupFilterAll'

export default {
    components: {
        'color-setup' : ColorSetup,
        'color-filter-all': ColorFilterAll
    },
    data(){
        return {
            color: false,
            saveMsj   : false,
            saveColor : '',
            saveText  : '',
            current: {},
            modelSchema: {
                colors : [],

                //- Filtros de colores
                colors_states : [],
                colors_cities : [],
                colors_zones  : [],
                colors_gender : [],
                colors_age    : [],
                colors_level  : [],
                colors_form   : []
            },
            model: {},
            preload: false,
            loadingBtn: false
        }
    },
    computed: {

        gender(){ 
            return [
                {
                    text: this.$t('Masculino'),
                    id: 1
                },
                {
                    text: this.$t('Femenino'),
                    id: 0
                },
                {
                    text: this.$t('Otro'),
                    id: 2
                }
            ]
        }
    },
    created(){
        this.model = this._.cloneDeep(this.modelSchema)
    },
    methods: {
        async open(item){

            this.current = this._.cloneDeep(item)
            this.model   = this._.cloneDeep(this.modelSchema)

            this.color = true
            this.preload = true
            await this.fetch()
            this.preload = false
        },
        fetch(){
            return new Promise((resolve) => {

                this.$api(this, (xhr) => {
                    xhr.get('/network/colors', {
                        params: { networkid: this.current.uuid }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            
                            let model  = { 
                                colors        : JSON.parse(data.data.colors || '[]'),
                                colors_states : JSON.parse(data.data.colors_states || '[]'),
                                colors_cities : JSON.parse(data.data.colors_cities || '[]'),
                                colors_zones  : JSON.parse(data.data.colors_zones || '[]'),
                                colors_gender : JSON.parse(data.data.colors_gender || '[]'),
                                colors_age    : JSON.parse(data.data.colors_age || '[]'),
                                colors_level  : JSON.parse(data.data.colors_level || '[]'),
                                colors_form   : JSON.parse(data.data.colors_form || '[]')
                            }

                            this.model = this._.merge(this.model, model)
                            resolve()

                        }else{
                            resolve()
                        }

                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },
        save(){

            let post = this._.cloneDeep(this.model)
            post.colors    = JSON.stringify(post.colors)
            post.networkid = this.current.uuid

            post.colors_states = JSON.stringify(post.colors_states.map((s) => {

                s.model.country = s.model.country.uuid
                s.model.state   = s.model.state.uuid
                return s
            }))

            post.colors_cities = JSON.stringify(post.colors_cities.map((s) => {

                s.model.country = s.model.country.uuid
                s.model.state   = s.model.state.uuid
                s.model.city    = s.model.city.uuid

                return s
            }))

            post.colors_zones = JSON.stringify(post.colors_zones.map((s) => {

                s.model.country = s.model.country.uuid
                s.model.state   = s.model.state.uuid
                s.model.city    = s.model.city.uuid
                s.model.zones   = s.model.zones.map((z) => z.uuid).filter(z => z != undefined)
                return s
            }))

            post.colors_age    = JSON.stringify(post.colors_age)
            post.colors_gender = JSON.stringify(post.colors_gender)
            post.colors_form   = JSON.stringify(post.colors_form)
            post.colors_level  = JSON.stringify(post.colors_level)

            this.loadingBtn = true
            this.$api(this, (xhr) => {
                xhr.post('/network/colors', this.$qs.stringify(post)).then(async (r) => {

                    let data = r.data
                    this.loadingBtn = false
                    this.colorMsj = true

                    if(data.response){
                        
                        this.colorColor = 'success'
                        this.colorText = data.message

                        this.model = this._.cloneDeep(this.modelSchema)
                        this.color = false

                        //this.preload = true
                        //await this.fetch()
                        //this.preload = false

                    }else{
                        this.colorColor = 'error'
                        this.colorText = data.message
                    }
                }).catch(() => {
                    this.loadingBtn = false
                })
            })
        },
    }
 }
</script>