<template lang="pug">
div
    loader(v-if="$root.loader")
    template(v-else)
        header-component
            v-btn.no-margin(absolute dark fab top right color='primary' @click.stop="drawerRight = !drawerRight")
                v-icon search
        v-navigation-drawer.filters.grey.darken-3(fixed v-model="drawerRight" right app width="246")
            v-form
                v-toolbar.grey.darken-1(flat)
                    v-text-field(v-model="filter.query" :placeholder="$t('Buscar...')" dark)
                    v-icon(dark) search
                .filters-content
                    .white--text.mb-2 {{$t('Filtrar por')}}: 
                    v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                        v-text-field(slot='activator' :label='$t("Desde")' v-model='filter.from' readonly dark color="white")
                        v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                            v-spacer
                            v-btn(flat color='primary' @click.stop='modalDateStart = false') {{$t('Cancelar')}}
                            v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{$t('OK')}}
                    v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                        v-text-field(slot='activator' :label='$t("Hasta")' v-model='filter.to' readonly dark color="white")
                        v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                            v-spacer
                            v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{$t('Cancelar')}}
                            v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{$t('OK')}}
                .filters-content
                    .btns.btns-right
                        v-btn(@click="cleanFilter" color="primary") {{ $t('Limpiar') }}
        v-content
            v-snackbar(
            :timeout="3000",
            :top="true"
            :right="true"
            :color="networkColor"
            v-model="networkMsj")
                | {{ networkText }}
                v-btn(flat, dark, @click.native="networkMsj = false") {{ $t('cerrar') }}
            v-container.grid-list-xl(fluid)
                h1 {{$t('Redes')}}
                template(v-if="preload")
                    h3 {{$t('Cargando')}}...
                v-data-table.mb-5(v-else
                    ref="table"
                    :headers='headers'
                    :items='network'
                    :pagination.sync='pagination'
                    :rows-per-page-items="$root.rowsPageItems"
                    :rows-per-page-text="$root.rowsPageText" 
                    item-key='uuid'
                    :total-items="total"
                    :loading="loading"
                )
                    template(slot="no-results")
                        | {{$t('No se han encontrado resultados')}}
                    template(slot="no-data")
                        | {{$t('No se han encontrado resultados')}}
                    template(slot='headers' slot-scope='props')
                        tr
                            th(v-for='header in props.headers' :key='header.text' :align="header.align")
                                span.subheading {{ $t(header.text) }}
                    template(slot='items' slot-scope='props')
                        tr
                            td
                                v-avatar
                                    img(:src="props.item.share ? uploadurl + props.item.share + '?time=' + props.item.time : imgurl")
                            td.pa-2
                                span {{ props.item.name }}
                                br
                                small: b {{ props.item.alias }}
                            td
                                span {{ $t(props.item.enable == 1 ? 'Activa' : 'Inactiva') }}
                            td.text-xs-right
                                v-menu(left)
                                    v-icon(color='primary' dark slot='activator') more_vert
                                    v-list.sheet-menu
                                        v-list-tile.white--text(@click.native="open('edit', $t('Editar red'), props.item)")
                                            v-list-tile-title {{ $t('Editar') }}
                                        v-list-tile.white--text(v-if="$root.profile.type == 0 || $root.profile.type == 2" @click="$refs.form.open(props.item)")
                                            v-list-tile-title {{ $t('Formularios personalizados') }}
                                        v-list-tile.white--text(v-if="$root.profile.type == 0 || $root.profile.type == 2" @click="$refs.text.open(props.item)")
                                            v-list-tile-title {{ $t('Configuración de textos') }}
                                        v-list-tile.white--text(v-if="$root.profile.type == 0 || $root.profile.type == 2" @click="$refs.whatsAppText.open(props.item)")
                                            v-list-tile-title {{ $t('Configuración de textos para WhatsApp') }}
                                        v-list-tile.white--text(v-if="$root.profile.type == 0 || $root.profile.type == 2" @click="$refs.color.open(props.item)")
                                            v-list-tile-title {{ $t('Colores de distribución de referidos') }}
                                        v-list-tile.white--text(v-if="$root.profile.type == 0 || $root.profile.type == 2" @click="$refs.customNetwork.open(props.item)")
                                            v-list-tile-title {{ $t('Personalización de redes por usuario') }}
                                        v-list-tile.white--text(v-if="$root.profile.type == 0 || $root.profile.type == 2" @click="$refs.hightlightNetwork.open(props.item)")
                                            v-list-tile-title {{ $t('Destacar redes') }}
                                        v-list-tile.white--text(v-if="$root.profile.type == 0 || $root.profile.type == 2" @click="$refs.wpGroup.open(props.item)")
                                            v-list-tile-title {{ $t('Grupos de WhatsApp') }}
                                        v-list-tile.white--text(v-if="$root.profile.type == 0 || $root.profile.type == 2" @click="$refs.crmForm.open(props.item)")
                                            v-list-tile-title {{ $t('Formularios CRM') }}
                v-btn(v-if="$root.profile.type == 0 || Object.keys($root.profile.available).length" @click.native="open('add', $t('Agregar red'))" fixed dark fab bottom right color='primary')
                    v-icon add
                forms(v-if="$root.profile.type == 0 || $root.profile.type == 2" ref="form")
                texts(v-if="$root.profile.type == 0 || $root.profile.type == 2" ref="text")
                
                // Personalizar textos WhatsApp
                custom-whatsapp-text(v-if="$root.profile.type == 0 || $root.profile.type == 2" ref="whatsAppText")

                color-network(v-if="$root.profile.type == 0 || $root.profile.type == 2" ref="color")
                custom-network(v-if="$root.profile.type == 0 || $root.profile.type == 2" ref="customNetwork")
                hightlight-network(v-if="$root.profile.type == 0 || $root.profile.type == 2" ref="hightlightNetwork" @success="fetchNetwork")
                wp-group(v-if="$root.profile.type == 0 || $root.profile.type == 2" ref="wpGroup" @success="fetchNetwork")
                crm-form(v-if="$root.profile.type == 0 || $root.profile.type == 2" ref="crmForm")
                v-dialog(v-model="networkModal" max-width="800" persistent)
                    v-form
                        v-card
                            v-card-title {{ $t('Crear/editar Red') }}
                            v-card-text
                                template(v-if="preload")
                                    h3 {{ $t('Cargando') }}...
                                v-layout(row wrap v-else)
                                    template(v-if="Object.keys(networkModel).length")
                                        v-flex(xs12 sm7)
                                            v-text-field(:label="$t('Nombre')", v-model="networkModel.name")
                                        v-flex(xs12 sm5)
                                            v-text-field(:label="$t('Alias')" v-model="networkModel.alias")
                                        v-flex(xs12)
                                            v-text-field(:label="$t('Whatsapp')" v-model="networkModel.whatsapp")
                                        v-flex(xs12)
                                            v-text-field(:label="$t('Whatsapp API')" :hint="$t('Ingrese el número de WhatsApp para uso con el API')" v-model="networkModel.whatsapp_api")
                                        v-flex(xs12)
                                            v-text-field(:label="$t('Dominio corto')" v-model="networkModel.short_domain")
                                        v-flex(xs12)
                                            v-text-field.code--text--input(:label="$t('Código de referido por defecto')" v-model="networkModel.code")
                                        template
                                            v-flex.mt-3(xs12)
                                                h3 {{ $t('Subadministradores de la red') }}
                                            v-flex(xs12 v-for="(email, i) in networkModel.email" :key="'email' + i")
                                                v-text-field(browser-autocomplete='new-password' label="Email" v-model="networkModel.email[i]" append-icon="close" :append-icon-cb="() => deleteEmail(i)")
                                            v-flex(xs12)
                                                v-btn.primary.mt-2.mx-0(@click="networkModel.email.push('')")
                                                    v-icon add
                                                    | {{ $t('Agregar correo electrónico') }}
                                                br
                                            v-flex.mt-3(xs12)
                                                h3 {{ $t('Subadministradores de la red (Comentaristas)') }}
                                            v-flex(xs12 v-for="(email, i) in networkModel.email_comment" :key="'email' + i")
                                                v-text-field(browser-autocomplete='new-password' label="Email" v-model="networkModel.email_comment[i]" append-icon="close" :append-icon-cb="() => deleteEmailComment(i)")
                                            v-flex(xs12)
                                                v-btn.primary.mt-2.mx-0(@click="networkModel.email_comment.push('')")
                                                    v-icon add
                                                    | {{ $t('Agregar correo electrónico') }}
                                                br
                                            v-flex.mt-3(xs12)
                                                h3 {{$t('Subadministradores galería de la red')}}
                                            v-flex(xs12 v-for="(email, i) in networkModel.gallery" :key="'gallery' + i")
                                                v-text-field(browser-autocomplete='new-password' label="Email" v-model="networkModel.gallery[i]" append-icon="close" :append-icon-cb="() => deleteEmailGallery(i)")
                                            v-flex(xs12)
                                                v-btn.primary.mt-2.mx-0(@click="networkModel.gallery.push('')")
                                                    v-icon add
                                                    | {{$t('Agregar correo electrónico')}}
                                                br
                                            v-flex.mt-3(xs12)
                                                h3 {{$t('Subadministradores banners de la red')}}
                                            v-flex(xs12 v-for="(email, i) in networkModel.banner" :key="'banner' + i")
                                                v-text-field(label="Email" v-model="networkModel.banner[i]" append-icon="close" :append-icon-cb="() => deleteEmailBanner(i)")
                                            v-flex(xs12)
                                                v-btn.primary.mt-2.mx-0(@click="networkModel.banner.push('')")
                                                    v-icon add
                                                    | {{$t('Agregar correo electrónico')}}
                                                br
                                        v-flex(xs12)
                                            v-select(:items="$root.languagesLabel" item-text="label" item-value="value" :label="$t('Idioma de la red')" v-model="networkModel.language")
                                        language(custom :language="networkModel.language" :show-title="false")
                                            template(slot="default" slot-scope="props")
                                                v-layout(row wrap)
                                                    v-flex(xs12)
                                                        h4.my-2 {{$t('Texto a compartir')}}
                                                        v-text-field(v-if="props.default" multi-line v-model="networkModel.text")
                                                        v-text-field(v-else multi-line v-model="networkModel.translation[props.lang].text")
                                                    v-flex(xs12)
                                                        h4.my-2 {{$t('Términos y condiciones')}}
                                                        editor(v-if="props.default" :init="$root.editor" v-model="networkModel.terms")
                                                        editor(v-else :init="$root.editor" v-model="networkModel.translation[props.lang].terms")
                                                    v-flex(xs12)
                                                        h4.my-2 {{$t('Políticas de privacidad')}}
                                                        editor(v-if="props.default" :init="$root.editor" v-model="networkModel.priv")
                                                        editor(v-else :init="$root.editor" v-model="networkModel.translation[props.lang].priv")
                                                    v-flex(xs12)
                                                        h4.my-2 {{$t('Texto de registro/inicio de sesión')}}
                                                        editor(v-if="props.default" :init="$root.editor" v-model="networkModel.logintext")
                                                        editor(v-else :init="$root.editor" v-model="networkModel.translation[props.lang].logintext")
                                                    v-flex(xs12)
                                                        h4.my-2 {{$t('Texto de inicio')}}
                                                        editor(v-if="props.default" :init="$root.editor" v-model="networkModel.hometext")
                                                        editor(v-else :init="$root.editor" v-model="networkModel.translation[props.lang].hometext")
                                        v-flex(xs12)
                                            label {{$t('Habilitado')}}
                                            v-divider.mt-2
                                            .switch-group
                                                label {{$t('No')}}
                                                v-switch(:label="$t('Si')", color="success", v-model="networkModel.enable")
                                            br
                                        v-flex(xs12 sm6)
                                            h3.mb-3 {{$t('Foto para inicio')}}
                                            v-btn.btn-file(color="success" outline block large) 
                                                v-icon(left) file_upload
                                                | {{$t('Subir foto')}}
                                                input(type='file' @change='fileChange($event, "shareImg", "share")')
                                            .img-file(v-if="networkModel.shareImg.length")
                                                img(:src="networkModel.shareImg")
                                                v-icon(@click='removeImage("shareImg", "share")') close
                                            .img-file(v-else)
                                                img(:src="networkModel.share ? uploadurl + networkModel.share + '?time=' + networkModel.time : imgurl")
                                        v-flex(xs12 sm6)
                                            h3.mb-3 {{$t('Foto para registro')}}
                                            v-btn.btn-file(color="success" outline block large) 
                                                    v-icon(left) file_upload
                                                    | {{$t('Subir foto')}}
                                                    input(type='file', @change='fileChange($event, "registerImg", "register")')
                                            .img-file(v-if="networkModel.registerImg.length")
                                                img(:src='networkModel.registerImg')
                                                v-icon(@click='removeImage("registerImg", "register")') close
                                            .img-file(v-else)
                                                img(:src="networkModel.register ? uploadurl + networkModel.register + '?time=' + networkModel.time : imgurl")
                                        template(v-if="node.length")
                                            v-flex.mt-3(xs12)
                                                h3 {{$t('Disponibilidad regional de la red')}}
                                            v-flex(xs12)
                                                v-btn.primary(small @click="selectAll") {{ $t('Seleccionar todos') }}
                                                v-btn.primary(small @click="unselectAll") {{ $t('Deseleccionar todos') }}
                                                liquor-tree(
                                                    ref="treeNode"
                                                    :data="node"
                                                    :options="tree"
                                                )
                                            template(v-if="$root.profile.type == 0")
                                                v-flex.mt-3(xs12)
                                                    h3 {{$t('Retos de los usuarios de la red')}}
                                                v-flex(xs12)
                                                    label {{$t('Habilitado')}}
                                                    v-divider.mt-2
                                                    .switch-group
                                                        label {{$t('No')}}
                                                        v-switch(:label="$t('Si')" color="success" v-model="networkModel.isgoal")
                                                    br
                                                template(v-if="networkModel.isgoal")
                                                    v-flex.mt-3(xs12 v-if="$root.profile.type != 0")
                                                        h3 {{$t('Retos de los usuario de la red')}}
                                                    v-flex(xs12 sm6)
                                                        v-text-field(:label="$t('Retos de referidos por mes')" type="number" v-model="networkModel.goal.month")
                                                    v-flex(xs12 sm6)
                                                        v-text-field(:label="$t('Retos de referidos por semana')" type="number" v-model="networkModel.goal.week")
                                                    v-flex(xs12 sm6)
                                                        v-text-field(:label="$t('Retos de referidos por día')" type="number" v-model="networkModel.goal.day")
                                                    v-flex(xs12 sm6)
                                                        v-text-field(:label="$t('Retos de referidos directos por mes')" type="number" v-model="networkModel.goal.smonth")
                                                    v-flex(xs12 sm6)
                                                        v-text-field(:label="$t('Retos de referidos directos por semana')" type="number" v-model="networkModel.goal.sweek")
                                                    v-flex(xs12 sm6)
                                                        v-text-field(:label="$t('Retos de referidos directos por día')" type="number" v-model="networkModel.goal.sday")
                                                v-flex.mt-3(xs12)
                                                    h3 {{$t('Gráfico de usuarios totales')}}
                                                v-flex(xs12)
                                                    label {{$t('Habilitado')}}
                                                    v-divider.mt-2
                                                    .switch-group
                                                        label {{$t('No')}}
                                                        v-switch(:label="$t('Si')" color="success" v-model="networkModel.istotal")
                                                    br
                                        v-flex(xs12)
                                            label {{$t('Rutas compartidas')}}
                                            v-divider.mt-2
                                            .switch-group
                                                label {{$t('No')}}
                                                v-switch(:label="$t('Si')" color="success" v-model="networkModel.routes")
                                        v-flex(xs12)
                                            label {{$t('Solo mayores de edad')}}
                                            v-divider.mt-2
                                            .switch-group
                                                label {{$t('No')}}
                                                v-switch(:label="$t('Si')" color="success" v-model="networkModel.adult")
                                        v-flex(xs12)
                                            label {{$t('Deshabilitar tabla de primeras 50 posiciones')}}
                                            v-divider.mt-2
                                            .switch-group
                                                label {{$t('No')}}
                                                v-switch(:label="$t('Si')" color="success" v-model="networkModel.fifty_pos")
                                        v-flex.mt-3(xs12)
                                            h3 {{$t('Grupo de WhatsApp por defecto')}}
                                        v-flex(xs12)
                                            v-text-field(:label="$t('Nombre del grupo')" v-model="networkModel.wpgroup")
                                        v-flex(xs12)
                                            v-text-field(:label="$t('Enlace del grupo')" v-model="networkModel.wpgroupurl")
                                        v-flex(xs12)
                                            label {{$t('Casas de registro')}}
                                            v-divider.mt-2
                                            .switch-group
                                                label {{$t('No')}}
                                                v-switch(:label="$t('Si')" color="success" v-model="networkModel.standregister")
                                        v-flex(xs12 v-if="$root.profile.type == 0")
                                            label {{$t('Habilitar captcha')}}
                                            v-divider.mt-2
                                            .switch-group
                                                label {{$t('No')}}
                                                v-switch(:label="$t('Si')" color="success" v-model="networkModel.recaptcha")
                            v-card-actions
                                .btns.btns-right
                                    v-btn(color="secondary", small, @click.native="networkModal = false") {{$t('Cancelar')}}
                                    v-btn(:loading="loadingBtn" color="primary" large @click.native="saveNetwork") {{$t('Guardar')}}
</template>

<script>

    import auth from 'mixins/auth'
    import config from 'src/config'
    import Vue from 'vue'
    import _ from 'lodash/debounce'
    import ColorNetwork from 'components/colors/Network'
    import CustomNetwork from 'components/network/CustomNetwork'
    import HightlightNetwork from 'components/network/HighlightNetwork'
    import WpGroup from 'components/network/WpGroup'
    import CrmForm from 'components/crm/Form'
    import CustomWhatsAppText from 'components/whatsapp/CustomWhatsAppText'

    export default {
        mixins: [auth],
        metaInfo() {
            return {
                title: this.$t('Redes')
            }
        },
        data() {
            return {
                networkModal : false,
                network      : [],
                titleNetwork : '',
                updateNetwork: false,
                networkSchema: {
                    uuid       : '',
                    name       : '',
                    alias      : '',
                    text       : '',
                    enable     : false,
                    shareImg   : '',
                    registerImg: '',
                    share      : '',
                    register   : '',
                    whatsapp: '',
                    whatsapp_api: '',
                    priv       : '',
                    terms      : '',
                    hometext   : '',
                    logintext  : '',
                    short_domain: '',
                    email: [],
                    email_comment: [],
                    gallery    : [],
                    banner     : [],
                    availability: [],
                    isgoal: false,
                    istotal: false,
                    fifty_pos: false,
                    goal: {
                        month: 0,
                        week : 0,
                        day  : 0,
                        smonth: 0,
                        sweek: 0,
                        sday: 0
                    },
                    translation: {},
                    language: 'es',
                    code: '',
                    routes: false,
                    adult: false,
                    wpgroup: '',
                    wpgroupurl: '',
                    standregister: false,
                    recaptcha: false
                },
                networkModel: {},
                networkMsj  : false,
                networkColor: '',
                networkText : '',
                loadingBtn  : false,
                node: [],
                tree: {
                    multiple: true,
                    checkbox: true,
                    checkOnSelect:true,
                    parentSelect: true,
                    fetchData: (node) => {
                        return new Promise((resolve) => {

                            let url  = '/state'
                            let type = 1
                            let params = {
                                filter: JSON.stringify({
                                    status: 1
                                })
                            }

                            if(node.data.type == 1){
                                params.countryid = node.data.uuid
                                type = 2
                            }

                            if(node.data.type == 2){
                                url  = '/city'
                                params.stateid = node.data.uuid
                                type = 3
                            }

                            if(node.data.type == 3){
                                url  = '/zone'
                                params.cityid = node.data.uuid
                                params.parent = 0
                                type = 4
                            }

                            if(node.data.type == 4){
                                url  = '/zone'
                                params.cityid = node.data.cityid
                                params.parent = node.data.uuid
                                type = 4
                            }

                            this.$api(this, (xhr) => {
                                xhr.get(url, {params}).then((r) => {

                                    let data = r.data
                                    let node = data.data.length ? data.data : []

                                    node = node.map((node) => {
                                        node.type = type                                        
                                        return {
                                            text: node.name,
                                            data: node,
                                            isBatch: true
                                        }
                                    })

                                    resolve(node.map(this.checkNode))
                                }).catch(() => {
                                    resolve([])
                                })
                            })
                        })
                    }
                },
                searchRegion: '',
                total: 0,
                loading: false,
                preload: false,
                headers: [
                    {
                        text: '',
                        align: 'center',
                        value: 'share'
                    },
                    {
                        text: 'Red',
                        align: 'left',
                        value: 'name'
                    },
                    {
                        text: 'Estado',
                        align: 'left',
                        value: 'enable'
                    },
                    {
                        text: 'Opciones',
                        align: 'center',
                        value: ''
                    },
                ],
                pagination: {
                    page: 1,
                    rowsPerPage: 20
                },
                filter: {},
                filterSchema: {
                    query : '',
                    from  : null,
                    to    : null
                },
                drawerRight: false,
                modalDateStart: false,
                modalDateEnd: false
            }
        },
        watch: {
            filter: {
                handler(){

                    this.preload = true
                    this.fetchNetwork()
                },
                deep: true
            },
            pagination: {
                handler () {
                    this.fetchNetwork()
                },
                deep: true
            }
        },
        computed: {
            imgurl(){
                return config.imgurl
            },
            homeurl(){
                return config.homeurl
            },
            uploadurl(){
                return config.uploadurl
            }
        },
        created(){

            this.setTranslation()
            this.networkModel = Object.assign({}, this.networkSchema)
            this.filter       = Object.assign({}, this.filterSchema)
            this.preload      = true
            this.fetchNetwork()
        },
        methods: {

            setTranslation(){

                let tl = {}
                for(let lang of this.$root.languages){
                    tl[lang] = {
                        text     : '',
                        priv     : '',
                        terms    : '',
                        hometext : '',
                        logintext: ''
                    } 
                }

                Vue.set(this.networkSchema, 'translation', tl)
            },

            //Establecer idioma por defecto
            setDefaultTranslation(){

                let tl = this.networkModel.translation.hasOwnProperty(this.networkModel.language) ? this.networkModel.translation[this.networkModel.language] : {}
                if(Object.keys(tl).length){

                    this.networkModel.terms     = 'terms' in tl ? tl.terms : ''
                    this.networkModel.priv      = 'priv' in tl ? tl.priv : ''
                    this.networkModel.logintext = 'logintext' in tl ? tl.logintext: ''
                    this.networkModel.hometext  = 'hometext' in tl ? tl.hometext : ''
                    this.networkModel.text      = 'text' in tl ? tl.text : ''
                }
            },

            open(action, title, data = this.networkSchema){
                
                this.setTranslation()
                this.networkModel = this._.cloneDeep(this.networkSchema)

                this.preload = true
                this.titleNetwork  = title
                this.updateNetwork = (action == 'add' ? false: true) 
                this.networkModel  = this._.merge(this.networkModel, data)

                this.networkModel.enable        = this.networkModel.enable   == 1 ? true : false
                this.networkModel.register      = this.networkModel.register == null ? '': this.networkModel.register
                this.networkModel.share         = this.networkModel.share    == null ? '': this.networkModel.share
                this.networkModel.isgoal        = this.networkModel.isgoal ? true : false
                this.networkModel.istotal       = this.networkModel.istotal ? true : false
                this.networkModel.fifty_pos     = this.networkModel.fifty_pos ? true : false
                this.networkModel.short_domain  = this.networkModel.short_domain == null ? '' : this.networkModel.short_domain
                this.networkModel.routes        = this.networkModel.routes   == 1 ? true : false
                this.networkModel.adult         = this.networkModel.adult == 1 ? true : false
                this.networkModel.standregister = this.networkModel.standregister == 1 ? true : false
                this.networkModel.recaptcha     = this.networkModel.recaptcha == 1 ? true : false

                // Ajustar idioma por defecto
                this.setDefaultTranslation()
                this.loadingBtn = false

                this.node = []
                this.fetchCountry()
                
                this.$nextTick(() => {
                    this.networkModal = true
                    this.preload = false
                })
            },

            filterNode(node){
                return true
            },

            checkNode(node){

                let found = this.networkModel.availability.filter((ab) => {
                    return node.data.type == ab.type && node.data.uuid == ab.typeid 
                })

                let checked = found.filter((node) => node.partial == 0) 
                let partial = found.filter((node) => node.partial == 1) 
                
                if(partial.length){
                    node.state = { indeterminate: true }
                }

                if(checked.length){
                    node.state = { checked: true }
                }

                return node
            },

            fetchCountry(){

                let params = {
                    filter: JSON.stringify({
                        status: 1
                    })
                }

                this.$api(this, (xhr) => {
                    xhr.get('/country', {params}).then((r) => {
                        let data = r.data
                        if(data.response){

                            let node = data.data

                            node = node.map((node) => {

                                node.type = 1
                                return {
                                    text : node.name,
                                    data : node,
                                    isBatch: true
                                }
                            })
                            
                            this.node = node.filter(this.filterNode).map(this.checkNode)
                        }
                    }).catch(() => {})
                })
            },
            
            fetchNetwork: _(function(){

                this.loading = true
                let filter = this.filter

                this.$api(this, (xhr) => {
                    xhr.get('/admin/network', {
                        params: {
                            limit  : this.pagination.rowsPerPage,
                            offset : (this.pagination.page-1)*this.pagination.rowsPerPage,
                            filter : JSON.stringify(filter),
                        }
                    }).then((r) => {
                        let data = r.data
                        this.loading = false
                        if(data.response){
                            this.total = data.total
                            this.network = data.data
                            this.preload = false
                        }
                    }).catch(() => {})
                })
            }, 500),

            saveNetwork(){

                if(this.$refs.treeNode == undefined){
                    this.networkColor = 'error'
                    this.networkText = "No puedes crear redes porque no tienes regiones disponibles, contacta al administrador"
                    this.networkMsj = true
                }

                let nodes = this.$refs.treeNode.findAll({})
                nodes = nodes == null ? [] : nodes
                nodes = nodes.filter((node) => {
                    return node.indeterminate() || node.checked()
                })

                nodes = nodes.map((node) => {
                    return {
                        partial: node.indeterminate() ? 1 : 0,
                        type   : node.data.type,
                        typeid : node.data.uuid
                    }
                })
                
                this.setDefaultTranslation()

                let post    = this._.cloneDeep(this.networkModel)
                post.enable        = post.enable ? 1 : 0
                post.email         = JSON.stringify(post.email)
                post.email_comment = JSON.stringify(post.email_comment)
                post.gallery       = JSON.stringify(post.gallery)
                post.banner        = JSON.stringify(post.banner)
                post.availability  = JSON.stringify(nodes)
                post.isgoal        = post.isgoal ? 1 : 0
                post.istotal       = post.istotal ? 1 : 0
                post.fifty_pos     = post.fifty_pos ? 1 : 0
                post.translation   = JSON.stringify(post.translation)
                post.routes        = post.routes ? 1 : 0
                post.adult         = post.adult ? 1 : 0
                post.standregister = post.standregister ? 1 : 0
                post.recaptcha     = post.recaptcha ? 1 : 0

                post.goal = JSON.stringify([
                    {type:3,goal:this.networkModel.goal.month},
                    {type:4,goal:this.networkModel.goal.week},
                    {type:5,goal:this.networkModel.goal.day},
                    {type:7,goal:this.networkModel.goal.smonth},
                    {type:8,goal:this.networkModel.goal.sweek},
                    {type:9,goal:this.networkModel.goal.sday},
                ])

                let form = new FormData()
                for(let i in post){
                    form.append(i, post[i])
                }

                this.loadingBtn = true
                this.$api(this, (xhr) => {
                    xhr.post('/network', form).then((r) => {

                        let data = r.data
                        this.loadingBtn = false
                        this.networkMsj = true

                        if(data.response){

                            this.networkColor = 'success'
                            this.networkText = data.message
                            this.networkModal = false
                            
                            this.fetchNetwork()

                        }else{
                            this.networkColor = 'error'
                            this.networkText = data.message
                        }
                    }).catch(() => {})
                })
            },

            fileChange(e, model, save) {

                var files = e.target.files || e.dataTransfer.files
                if (!files.length) return
                this.createImage(files[0], model, save)
            },

            createImage(file, model, save) {

                let reader = new FileReader();
                reader.onload = (e) => this.networkModel[model] = e.target.result
                reader.readAsDataURL(file)
                this.networkModel[save] = file
            },

            removeImage(model, save) {
                this.networkModel[model] = ''
                this.networkModel[save] = ''
            },

            deleteEmail(i){
                this.networkModel.email.splice(i, 1)
            },

            deleteEmailComment(i){
                this.networkModel.email_comment.splice(i, 1)
            },

            deleteEmailGallery(i){
                this.networkModel.gallery.splice(i, 1)
            },

            deleteEmailBanner(i){
                this.networkModel.banner.splice(i, 1)
            },

            cleanFilter(){
                this.filter = Object.assign({}, this.filterSchema)
            },

            selectAll(){
                let tree = this.$refs.treeNode.findAll({})
                tree.check(true)
            },

            unselectAll(){
                let tree = this.$refs.treeNode.findAll({})
                tree.uncheck(true)
            }
        },
        components: {
            'color-network': ColorNetwork,
            'custom-whatsapp-text': CustomWhatsAppText,
            CustomNetwork,
            HightlightNetwork,
            WpGroup,
            CrmForm
        }
    }
</script>