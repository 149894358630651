<template lang="pug">
    div
        v-snackbar(
        :timeout="3000"
        :top="true"
        :right="true"
        :color="saveColor"
        v-model="saveMsj")
            | {{ saveText }}
            v-btn(flat dark @click.native="saveMsj = false") {{ $t('cerrar') }}
        v-dialog(lazy max-width="900px" v-model="custom")
            v-card
                v-card-title 
                    .btns.btns-right
                        v-btn(icon color="secondary" small @click.native="custom=false")
                            v-icon close
                    h3.full-width {{ $t('Personalización de redes por usuario') }}
                v-card-text
                    template(v-if="preload")
                        h3 {{$t('Cargando')}}...
                    template(v-else)
                        v-layout(row wrap)
                            v-flex(xs12)
                                label {{$t('Personalización de redes por usuario')}}
                                v-divider.mt-2
                                .switch-group
                                    label {{$t('No')}}
                                    v-switch(:label="$t('Si')" color="success" v-model="model.custom")
                            template(v-if="model.custom")
                                v-flex(xs12)
                                    label {{ $t('Seleccione un usuario') }}
                                    multiselect.mt-2(
                                        :append-to-body="true"
                                        v-model="user"
                                        :options="users"
                                        :placeholder="$t('Buscar usuario')"
                                        @search-change="searchUsers"
                                        :searchable="true"
                                        :loading="loadingUser"
                                        label="names"
                                        track-by="uuid"
                                        :internal-search="false"
                                        :show-no-results="false"
                                        :show-labels="false"
                                        :allow-empty="false"
                                        :clear-on-select="false"
                                        :hide-selected="false"
                                    )
                                        template(slot="option" slot-scope="props")
                                            .option__desc
                                                span.d-block {{ props.option.names }}
                                                small.d-block {{ props.option.email }}
                                        span(slot="noOptions") {{ $t('Lista vacia') }}
                                v-flex(xs12 v-if="Object.keys(user).length")
                                    v-btn.primary.mt-2(small @click="addUser(user)")
                                        v-icon add
                                        | {{ $t('Agregar usuario') }}
                                v-flex(xs12)
                                    v-list
                                        v-list-tile(v-for="(user, i) in model.users" :key="'user'+i")
                                            v-list-tile-content
                                                v-list-tile-title
                                                    small {{ user.names }} - {{ user.email }}
                                                v-list-tile-sub-title
                                                    v-switch.px-2.small(:label="$t('¿Puede personalizar la red?')" color="success" v-model="user.editable")
                                            v-list-tile-action
                                                v-icon(@click="model.users.splice(i, 1)") delete
                v-card-actions
                    .btns.btns-right
                        v-btn(color="secondary" small @click="custom=false") {{$t('Aceptar')}}
                        v-btn(v-if="!preload" color="secondary" :loading="loadingBtn" :disabled="loadingBtn" small @click="save") {{$t('Guardar')}}
</template>

<script>

import Multiselect from 'vue-multiselect'
export default {
    data(){
        return {
            custom    : false,
            saveMsj   : false,
            saveColor : '',
            saveText  : '',
            preload   : true,
            current   : {},
            modelSchema: {
                custom : false,
                users  : []
            },
            model: {},
            loadingBtn: false,

            // Buscador de usuarios
            user: {},
            users: [],
            loadingUser: false
        }
    },
    watch:{
        'model.custom'(){
            this.users = []
            this.model.users = []
        }
    },
    methods: {
        async open(item){

            this.user = {}
            this.users = []

            this.current = this._.cloneDeep(item)
            this.model   = this._.cloneDeep(this.modelSchema)

            await this.$nextTick()

            this.custom  = true
            this.preload = true
            await this.fetch()
            this.preload = false
        },
        save(){

            if(this.model.custom && !this.model.users.length){

                this.saveMsj   = true
                this.saveColor = 'error'
                this.saveText  = this.$t('Seleccione al menos un usuario')
                return
            }

            let post = {
                custom   : this.model.custom ? 1 : 0,
                users    : JSON.stringify(this.model.users.map((u) => {
                    return {
                        uuid: u.uuid,
                        editable: u.editable ? 1 : 0
                    }
                })),
                network  : this.current.uuid
            }

            this.loadingBtn = true
            this.$api(this, (xhr) => {
                xhr.post('/custom-network', this.$qs.stringify(post)).then((r) => {

                    let data = r.data
                    this.loadingBtn = false
                    this.saveMsj    = true
    
                    if(data.response){
                        this.saveColor = 'success'
                    }else{
                        this.saveColor = 'error'
                    }

                    this.saveText = this.$t(data.message)

                }).catch(() => {

                    this.loadingBtn = false
                    this.saveMsj    = true
                    this.saveColor  = 'error'
                    this.saveText   = this.$t('Ha ocurrido un error')
                })
            })
        },
        searchUsers(query){
                
            if(query.length < 3){
                return
            }

            this.loadingUser = true
            this.$api(this, (xhr) => {
                xhr.get('/custom-network/users', {
                    params: {
                        query,
                        network: this.current.uuid
                    }
                }).then((r) => {
                    
                    let data = r.data
                    this.loadingUser = false
                    this.users = data.response ? data.data : []

                }).catch(() => {
                    this.users = []
                    this.loadingUser = false
                })
            })
        },
        addUser(user){
            
            if(this.model.users.some(u => u.uuid == user.uuid)){
                return
            }

            this.model.users.push({...user, editable: true})
            this.user = {}
        },
        fetch(){
            return new Promise((resolve) => {

                this.$api(this, (xhr) => {
                    xhr.get('/custom-network', {
                        params: { network: this.current.uuid }
                    }).then(async (r) => {

                        let data = r.data
                        if(data.response){

                            this.model.custom = data.data.custom == 1 ? true : false
                            this.$nextTick(() => {
                                this.model.users = data.data.users
                            })

                            resolve()
                        }else{
                            resolve()
                        }

                    }).catch(() => {
                        resolve()
                    })
                })
            })
        }
    },
    components: {
        Multiselect
    }
}

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>