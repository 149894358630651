<template lang="pug">
    div
        v-snackbar(
        :timeout="3000"
        :top="true"
        :right="true"
        :color="alertColor"
        v-model="alertMsj")
            | {{ alertText }}
            v-btn(flat dark @click.native="alertMsj = false") {{ $t('cerrar') }}
        v-dialog(lazy max-width="900px" v-model="highlight")
            v-card
                v-card-title 
                    .btns.btns-right
                        v-btn(icon color="secondary" small @click.native="highlight=false")
                            v-icon close
                    h3.full-width {{ $t('Destacar redes') }} - {{ current.name }}
                v-card-text
                    template(v-if="preload")
                        h3 {{$t('Cargando')}}...
                    template(v-else)
                        v-layout(row wrap)
                            p: i {{ $t('Destacar redes en la página de inicio de la red') }}
                            v-flex(xs12)
                                v-select.mb-3(
                                    v-model="model.network"
                                    :items='networks'
                                    item-text="name"
                                    item-value="uuid"
                                    :label='$t("Red")'
                                    append-icon="keyboard_arrow_down" 
                                    autocomplete
                                    :filter="vFilter"
                                    :return-object="true"
                                )
                                .btns.btns-right
                                    v-btn(color="secondary" small @click="addNetwork") {{ $t('Agregar red') }}
                            v-flex(xs12)
                                draggable(tag="v-list" :list.sync="model.networks" :options="optsDrag")
                                    v-list-tile.opts--drag(v-for="(network, i) in model.networks" :key="'network'+i")
                                        v-list-tile-action
                                            v-icon.opts--handle.cur-p.primary--text drag_indicator
                                        v-list-tile-content {{ network.name }}
                                        v-list-tile-action(v-if="$root.profile.type == 0 ? true : (networks.some(n => n.uuid == network.uuid))")
                                            v-icon(@click="model.networks.splice(i, 1)") delete
                        v-layout.mt-4(row wrap)
                            h4.full-width {{ $t('Destacar redes por condiciones') }}
                            p: i {{ $t('Destacar redes en la página de inicio de la red') }}
                            v-flex(xs12)
                                v-select.mb-3(
                                    v-model="modelRule.network"
                                    :items='networks'
                                    item-text="name"
                                    item-value="uuid"
                                    :label='$t("Red")'
                                    append-icon="keyboard_arrow_down" 
                                    autocomplete
                                    :filter="vFilter"
                                    :return-object="true"
                                )
                            v-flex(xs12 sm6)
                                v-select.mb-3(
                                    v-model="modelRule.country"
                                    :items='countries'
                                    item-text="name"
                                    item-value="uuid"
                                    :label='$t("País")'
                                    append-icon="keyboard_arrow_down" 
                                    autocomplete
                                    :filter="vFilter"
                                    :return-object="true"
                                    @input="modelRule.state={},modelRule.city={},states=[],cities=[],fetchState()"
                                )
                            v-flex(xs12 sm6)
                                v-select.mb-3(
                                    v-model="modelRule.state"
                                    :items='states'
                                    item-text="name"
                                    item-value="uuid"
                                    :label='$t("Estado/Departamento")'
                                    append-icon="keyboard_arrow_down" 
                                    autocomplete
                                    :filter="vFilter"
                                    :return-object="true"
                                    @input="modelRule.city={},cities=[],fetchCity()"
                                )
                            v-flex(xs12 sm6)
                                v-select.mb-3(
                                    v-model="modelRule.city"
                                    :items='cities'
                                    item-text="name"
                                    item-value="uuid"
                                    :label='$t("Ciudad")'
                                    append-icon="keyboard_arrow_down" 
                                    autocomplete
                                    :filter="vFilter"
                                    :return-object="true"
                                )
                            v-flex(xs12 sm6)
                                v-select(
                                    v-model="modelRule.gender"
                                    :items='gender'
                                    item-text="text"
                                    item-value="id"
                                    :label='$t("Género")'
                                    append-icon="keyboard_arrow_down" 
                                    autocomplete
                                    :return-object="true"
                                    :filter="vFilter"
                                )
                            v-flex(xs12 sm6)
                                v-select(
                                    v-model="modelRule.age"
                                    :items='ages'
                                    item-text="text"
                                    item-value="id"
                                    :label='$t("Rango de edad")'
                                    append-icon="keyboard_arrow_down" 
                                    autocomplete
                                    :return-object="true"
                                    :filter="vFilter"
                                )
                            v-flex(xs12)
                                .btns.btns-right
                                    v-btn(color="secondary" small @click="addNetworkRule") {{ $t('Agregar red') }}
                            v-flex.network_rule(xs12)
                                draggable(tag="v-list" :list.sync="modelRule.networks" :options="optsRulesDrag")
                                    v-list-tile.optsr--drag(v-for="(network, i) in modelRule.networks" :key="'networkr'+i")
                                        v-list-tile-action
                                            v-icon.optsr--handle.cur-p.primary--text drag_indicator
                                        v-list-tile-content 
                                            b {{ network.network.name }}
                                            small
                                                template(v-if="network.country && Object.keys(network.country).length") {{ $t('País') }}: {{ network.country.name }}&nbsp;
                                                template(v-if="network.state && Object.keys(network.state).length") {{ $t('Estado/departamento') }}: {{ network.state.name }}&nbsp;
                                                template(v-if="network.city && Object.keys(network.city).length") {{ $t('Ciudad') }}: {{ network.city.name }}&nbsp;
                                                template(v-if="network.gender && Object.keys(network.gender).length") {{ $t('Género') }}: {{ network.gender.text }}&nbsp;
                                                template(v-if="network.age && Object.keys(network.age).length") {{ $t('Rango de edad') }}: {{ network.age.text }}&nbsp;

                                        v-list-tile-action(v-if="$root.profile.type == 0 ? true : (networks.some(n => n.uuid == network.network.uuid))")
                                            v-icon(@click="modelRule.networks.splice(i, 1)") delete
                v-card-actions
                    .btns.btns-right
                        v-btn(color="secondary" small @click.native="highlight = false") {{ $t('Cancelar') }}
                        v-btn(color="primary" large @click.native="save" :loading="loadingBtn") {{ $t('Guardar') }}
</template>

<script>

import config from 'src/config'
import fn from 'mixins/fn'
import draggable from 'vuedraggable'

export default {
    components: {
        draggable
    },
    mixins: [fn],
    data(){
        return {
            highlight : false,
            alertMsj   : false,
            alertColor : '',
            alertText  : '',
            preload    : true,
            current    : {},
            loadingBtn : false,
            networks   : [],
            countries  : [],
            states     : [],
            cities     : [],
            modelSchema: {
                network  : {},
                networks : []
            },
            modelRuleSchema: {
               network  : {},
               country  : {},
               state    : {},
               city     : {},
               gender   : {},
               age      : {},
               networks : [] 
            },
            modelRule: {},
            model: {},
            optsDrag: {
                group    : 'opts',
                draggable: '.opts--drag',
                handle   : '.opts--handle'
            },
            optsRulesDrag: {
                group    : 'optsr',
                draggable: '.optsr--drag',
                handle   : '.optsr--handle'
            }
        }
    },
    computed: {
        imgurl(){
            return config.imgurl
        },
        uploadurl(){
            return config.uploadurl
        }
    },
    methods: {

        fetchNetwork(){

            this.networks = []
            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/network', {
                        params: {
                            list    : 1,
                            exclude : JSON.stringify([this.current.uuid])
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.networks = data.data
                        }

                        resolve()

                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },
        fetchCountries(){

            this.countries = []
            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/country', {
                        params: {
                            filter: JSON.stringify({
                                status: 1
                            })
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.countries = data.data
                        }

                        resolve()

                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },
        fetchState(){

            this.states = []
            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/state', {
                        params: {
                            countryid: this.modelRule.country.uuid,
                            filter: JSON.stringify({
                                status: 1
                            })
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.states = data.data
                        }
                        resolve()
                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },
        fetchCity(){

            this.cities = []
            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/city', {
                        params: {
                            stateid: this.modelRule.state.uuid,
                            filter: JSON.stringify({
                                status: 1
                            })
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.cities = data.data
                        }
                        resolve()
                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },

        addNetwork(){
                
            let exists = this.model.networks.some(s => s.uuid == this.model.network.uuid)
            if(!exists){
                this.model.networks.push(this.model.network)
            }

            this.model.network = {}
        },

        addNetworkRule(){

            if(!Object.keys(this.modelRule.network).length){
                return
            }

            this.modelRule.networks.push({
                network: this.modelRule.network,
                country: this.modelRule.country,
                state  : this.modelRule.state,
                city   : this.modelRule.city,
                gender : this.modelRule.gender,
                age    : this.modelRule.age
            })

            let networks   = this.modelRule.networks.slice(0)
            this.modelRule = this._.cloneDeep(this.modelRuleSchema)
            this.modelRule.networks = networks
        },

        async open(item){

            this.preload = true

            this.current   = this._.cloneDeep(item)
            this.model     = this._.cloneDeep(this.modelSchema)
            this.modelRule = this._.cloneDeep(this.modelRuleSchema)

            await this.$nextTick()

            this.highlight = true
            await this.fetchNetwork()
            await this.fetchCountries()
            await this.fetch()

            this.preload = false
        },

        fetch(){

            this.model.networks = []
            return new Promise((resolve) => {

                this.$api(this, (xhr) => {
                    xhr.get('/highlight-network', {
                        params: {
                            network: this.current.uuid
                        }
                    }).then((r) => {
                        
                        let data = r.data
                        if(data.response){
                            this.model.networks = data.data.networks
                            let rules = data.data.rules.map((r => {

                                if('gender' in r){
                                    r.gender = this.gender.find(g => g.id == r.gender)
                                }

                                if('age' in r){
                                    r.age = this.ages.find(a => a.id == r.age)
                                }

                                return r
                            }))

                            if(rules.length){
                                this.modelRule.networks = rules
                            }
                        }
                        resolve()

                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },

        save(){

            this.loadingBtn = true

            let networkRules = this.modelRule.networks.map((n) => {
                return {
                    network: n.network.uuid,
                    country: !n.hasOwnProperty('country') ? '' : (typeof n.country == 'object' ? (Object.keys(n.country).length ? n.country.uuid : '') : ''),
                    state  : !n.hasOwnProperty('state') ? '' : (typeof n.state == 'object' ? (Object.keys(n.state).length ? n.state.uuid : '') : ''),
                    city   : !n.hasOwnProperty('city') ? '' : (typeof n.city == 'object' ? (Object.keys(n.city).length ? n.city.uuid : '') : ''),
                    gender : !n.hasOwnProperty('gender') ? '' : (typeof n.gender == 'object' ? (Object.keys(n.gender).length ? n.gender.id : '') : ''),
                    age    : !n.hasOwnProperty('age') ? '' : (typeof n.age == 'object' ? (Object.keys(n.age).length ? n.age.id : '') : ''),
                }
            })

            this.$api(this, (xhr) => {
                xhr.post('/highlight-network', this.$qs.stringify({
                    networks     : JSON.stringify(this.model.networks.map(n => n.uuid)),
                    network_rules: JSON.stringify(networkRules),
                    network      : this.current.uuid 
                })).then((r) => {

                    let data = r.data
                    this.loadingBtn = false
                    this.alertMsj   = true
                        
                    if(data.response){

                        this.alertColor = 'success'
                        this.alertText  = this.$t(data.message)
                        
                        this.highlight = false
                        this.$emit('success')
                    }else{
                        this.alertColor = 'error'
                        this.alertText  = this.$t(data.message)
                    }
                }).catch(() => {
                    this.loadingBtn = false
                })
            })
        }
    }
}

</script>