<template lang="pug">
div
    v-snackbar(
    :timeout="3000"
    :top="true"
    :right="true"
    :color="textColor"
    v-model="textMsj")
        | {{ textText }}
        v-btn(flat dark @click.native="textMsj = false") {{ $t('cerrar') }}
    v-dialog(lazy max-width="900px" v-model="modal" persistent)
        v-card
            v-card-title 
                .btns.btns-right
                    v-btn(icon color="secondary" small @click.native="modal=false")
                        v-icon close
                h3.full-width {{ $t('Red') }} ({{ current.name }})
            v-card-text
                template(v-if="preload")
                    h3 {{$t('Cargando')}}...
                template(v-else)
                    h2.mb-2 {{ $t('Configuración de textos para WhatsApp') }}
                    v-layout.mt-2(row wrap)
                        v-flex(xs12)
                            v-data-table(
                                hide-actions
                                :items="vars_items" 
                                :headers="vars_headers"
                            )
                                template(slot='headers' slot-scope='props')
                                    tr
                                        th(v-for='header in props.headers' :key='"vars_" + header.text' :align="header.align")
                                            span.subheading {{ $t(header.text) }}
                                template(slot="items" slot-scope="props")
                                    td {{ props.item.variable }}
                                    td.py-2(v-if="props.item.variable == '{custom|id}'") 
                                        | {{ $t(props.item.description) }}
                                        v-select(clearable v-model="customid" :items="customForm" item-text="placeholder" item-value="uuid")
                                        div.d-block(v-if="customid")
                                            | {custom|{{ customid }}}
                                            v-btn(small v-clipboard:success="copyTextSuccess" v-clipboard="() => '{custom|'+customid+'}'") {{ $t('Copiar') }}
                                        div(v-else) {{ $t('Seleccione un campo') }}
                                    td(v-else) {{ $t(props.item.description) }}
                    language(custom :language="current.language" :show-title="false")
                        template(slot="default" slot-scope="props")
                            v-layout.mt-2(row wrap)
                                v-flex(xs12)
                                    v-text-field(v-if="props.default" v-model="model.template_name" :label="$t('Nombre de la plantilla')")
                                    v-text-field(v-else v-model="model.translation[props.lang].template_name" :label="$t('Nombre de la plantilla')")
                                v-flex(xs12 sm6)
                                    v-text-field(v-if="props.default" multi-line v-model="model.template_content" :label="$t('Contenido de la plantilla')")
                                    v-text-field(v-else multi-line v-model="model.translation[props.lang].template_content" :label="$t('Contenido de la plantilla')")
                                v-flex(xs12 sm6)
                                    whatsapp-preview(:text="props.default ? model.template_content : model.translation[props.lang].template_content")
                    .btns.btns-right
                        v-btn(color="secondary" small @click="noeditable()" v-if="model.hasOwnProperty('editable') && model.editable") {{$t('Cancelar')}}
                        v-btn(color="primary" small @click="update" v-if="model.hasOwnProperty('editable') && model.editable") {{$t('Guardar plantilla')}}
                        v-btn(color="primary" small @click="add" v-else) {{ $t('Agregar plantilla') }}
                    v-layout.mt-2(v-if="templates.length" row wrap)
                        v-flex(xs12)
                            v-data-table(
                                :headers="tpl_headers"
                                :items="templates"
                                item-key="template_name"
                            )
                                template(slot='headers' slot-scope='props')
                                    tr
                                        th(v-for='header in props.headers' :key='"tpl_" + header.text' :align="header.align")
                                            span.subheading {{ $t(header.text) }}
                                template(slot="items" slot-scope="props")
                                    tr
                                        td {{ props.item.template_name }}
                                        td
                                            v-icon.cur-p(@click="editable(props.item)" color="primary") edit
                                            v-icon.cur-p(@click="templates.splice(props.index, 1)" color="primary") close
            v-card-actions
                .btns.btns-right
                    v-btn(color="primary" small @click="modal=false") {{$t('Aceptar')}}
                    v-btn(v-if="!preload" color="secondary" :loading="loadingBtn" :disabled="loadingBtn" small @click="save") {{$t('Guardar')}}
</template>

<script>

import Vue from 'vue'
export default {
    data(){
        return {
            modal     : false,
            textMsj   : false,
            textColor : '',
            textText  : '',
            current: {},
            customid: '',
            customForm: [],
            modelSchema: {
                template_name    : '',
                template_content : '',
                translation: {}
            },
            model: {},
            templates: [],
            tpl_headers: [
                {
                    text  : 'Plantilla',
                    value : 'template_name',
                    sortable: false,
                    align: 'left'
                },
                {
                    text  : 'Acciones',
                    value : 'actions',
                    sortable: false,
                    align: 'left'
                }
            ],
            vars_headers: [
                {
                    text  : 'Variable',
                    value : 'variable',
                    sortable: false,
                    align: 'left'
                },
                {
                    text  : 'Descripción',
                    value : 'description',
                    sortable: false,
                    align: 'left'
                }
            ],
            vars_items: [
                {
                    variable: '{current_names}',
                    description: 'Nombre del usuario actual'
                },
                {
                    variable: '{names}',
                    description: 'Nombre completo del usuario'
                },
                {
                    variable: '{name}',
                    description: 'Nombre del usuario'
                },
                {
                    variable: '{surname}',
                    description: 'Primer apellido del usuario'
                },
                {
                    variable: '{surname2}',
                    description: 'Segundo apellido del usuario'
                },
                {
                    variable: '{refer}',
                    description: 'Nombre del referidor'
                },
                {
                    variable: '{network}',
                    description: 'Nombre de la red'
                },
                {
                    variable: '{country}',
                    description: 'Nombre del país'
                },
                {
                    variable: '{state}',
                    description: 'Nombre del departamento/estado/provincia'
                },
                {
                    variable: '{city}',
                    description: 'Nombre de la ciudad'
                },
                {
                    variable: '{zones}',
                    description: 'Listado de las zonas separado por comas'
                },
                {
                    variable: '{custom|id}',
                    description: 'Campo de formulario personalizado'
                },
                {
                    variable: '{inviteurl}',
                    description: 'Enlace de invitación'
                },
                {
                    variable: '{shorturl}',
                    description: 'Enlace de la red personalizado'
                },
                {
                    variable: '{wpgroup}',
                    description: 'Nombre del grupo de WhatsApp'
                },
                {
                    variable: '{wpgroupurl}',
                    description: 'Enlace del grupo de WhatsApp'
                },
                {
                    variable: '{rank}',
                    description: 'Ranking global'
                },
                {
                    variable: '{total}',
                    description: 'Total de red'
                },
                {
                    variable: '{total_share}',
                    description: 'Total de referidos'
                },
                {
                    variable: '{code}',
                    description: 'Código de referido'
                }
            ],
            preload: false,
            loadingBtn: false
        }
    },
    created(){
        this.setTranslation()
        this.model = this._.cloneDeep(this.modelSchema)
    },
    methods: {
        setTranslation(){
            let tl  = {}
            for(let lang of this.$root.languages){

                tl[lang] = {
                    template_name: '',
                    template_content : ''
                }
            }

            Vue.set(this.modelSchema, 'translation', tl)
        },
        async open(item){

            this.current = this._.cloneDeep(item)
            this.modal = true
            this.preload = true

            this.customForm = []
            this.customid = ''
            this.templates = []
            
            await this.fetch()
            await this.fetchCustomForm()
            
            this.preload = false
        },
        fetchCustomForm(){
            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/network/form', {
                        params: { networkid: this.current.uuid }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            
                            let model = data.data
                            model.custom = model.custom.map((c) => {
                                return {
                                    uuid: c.uuid,
                                    placeholder: c.placeholder
                                }
                            })
                            
                            this.customForm = this._.cloneDeep(model.custom)
                            resolve()

                        }else{
                            resolve()
                        }

                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },
        resetModelSchema() {
            this.setTranslation()
            this.model = this._.cloneDeep(this.modelSchema)
        },
        validateItem() {
            try {

                this.setDefaultTranslation()

                if (!this.model.template_name.trim().length) {
                    throw this.$t('Ingrese un nombre para la plantilla')                    
                }

                if (!this.model.template_content.trim().length) {
                    throw this.$t('Ingrese un contenido para la plantilla')                    
                }

            } catch (e) {
                this.textMsj = true
                this.textColor = 'error'
                this.textText = e                      
            }
        },
        add() {
            this.validateItem()

            let post = this._.cloneDeep(this.model)
            post.uuid = this.$uuid()
            post.draft = true

            this.templates.push(post)
            this.resetModelSchema()
        },
        update() {
            this.validateItem()

            let post = this._.cloneDeep(this.model)
            post.editable = false

            let index = this.templates.findIndex(t => t.uuid == post.uuid)
            if (index != -1) {
                this.templates.splice(index, 1, post)
            } else {
                this.templates.push(post)
            }
            this.resetModelSchema()
        },
        editable(current_item) {

            let item = this._.cloneDeep(current_item)
            item.editable = true

            this.model = this._.cloneDeep(item)
        },
        noeditable() {
            this.resetModelSchema()
        },
        fetch(){
            return new Promise((resolve) => {

                this.$api(this, (xhr) => {
                    xhr.get('/whatsapp/invite/text', {
                        params: { networkid: this.current.uuid }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            
                            this.templates = data.data
                            this.setDefaultTranslation()

                            resolve()

                        }else{
                            resolve()
                        }

                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },
        save(){

            this.setDefaultTranslation()

            let post = {
                templates: JSON.stringify(this.templates),
                networkid: this.current.uuid
            }

            this.loadingBtn = true
            this.$api(this, (xhr) => {
                xhr.post('/whatsapp/invite/text', this.$qs.stringify(post)).then(async (r) => {

                    let data = r.data
                    this.loadingBtn = false
                    this.textMsj = true

                    if(data.response){
                        
                        this.textColor = 'success'
                        this.textText = data.message

                        this.setTranslation()
                        this.model = this._.cloneDeep(this.modelSchema)
                        
                        this.preload = true
                        await this.fetch()
                        await this.fetchCustomForm()
                        this.preload = false

                    }else{
                        this.textColor = 'error'
                        this.textText = data.message
                    }
                }).catch(() => {})
            })
        },
        setDefaultTranslation(){

            let tl = this.model.translation.hasOwnProperty(this.current.language) ? this.model.translation[this.current.language] : {}
            if(Object.keys(tl).length){

                this.model.template_name = 'template_name' in tl ? tl.template_name: ''
                this.model.template_content = 'template_content' in tl ? tl.template_content  : ''
            }
        },
        copyTextSuccess() {
            this.textMsj = true
            this.textColor = 'success'
            this.textText = this.$t('Copiado correctamente')
        }
    }
 }

</script>